<template>
  <div class="input-data">
    <div>
      <section>
        <sg-tab v-model="selectedAction"
                :items="[{label: $t('wq.general'), value: 'general'}, {label: $t('wq.chemical'), value: 'chemical'}, {label: $t('wq.biological'), value: 'biological'}]"
                class="px-4 border-top-0 mt-3 mb-5"/>

        <div class="is-flex is-align-items-start is-justify-content-space-between is-flex-wrap-wrap">
          <div class="is-flex is-flex-wrap-wrap">
            <sg-select :items="[{block_name: $t('all'), prepend: false},...selectedFarm.blocks]"
                       type="is-secondary is-light"
                       :prepend="$t('farm.block')"
                       class="mb-2 mr-2"
                       label="block_name"
                       v-model="selectedBlock"
                       :placeholder="`${$t('select')} ${$t('farm.block')}`"/>

            <b-datepicker
                class="mb-2 mr-2"
                :placeholder="$t('date')"
                icon="calendar-text"
                locale="id-ID"
                v-model="selectedDate[action]"
                @change="addData(action)"
                @input="addData(action)"
                editable/>

            <b-timepicker
                v-if="action !== 'general'"
                :placeholder="$t('time')"
                icon="calendar-text"
                locale="id-ID"
                class="mb-2"
                v-model="times[action]"
                editable/>
          </div>
          <b-button type="is-text" class="has-text-info has-shadow mb-2" @click="resetForm([selectedAction])" icon-left="sync">
            Reset Form
          </b-button>
        </div>

        <div>
          <div class="is-flex is-flex-wrap-wrap">
            <b-field v-for="(item, i) in data[action]" :key="i" class="mr-2">
              <p class="control">
                <b-button type="is-primary is-gradient"
                          :outlined="!$isSameDay(selectedDate[action], item.date)"
                          @click="selectedDate[action] = new Date(item.date)">
                  {{ $dateFormat(item.date) }}
                </b-button>
              </p>
              <p class="control">
                <b-button type="is-danger" outlined @click="removeData(i, action)" icon-left="close">
                </b-button>
              </p>
            </b-field>
          </div>
        </div>

        <div v-if="loaded">
          <div v-if="selectedGeneral.length">
            <div class="b-table custom-table" v-if="selectedAction === 'general'">
              <div class="table-wrapper has-sticky-header" :class="{embed: embed}">
                <table class="table is-bordered" id="table-general">
                  <thead>
                  <tr class="header-row">
                    <th rowspan="2">{{ $t('farm.pond') }}</th>
                    <th colspan="3" style="min-width: 100px">pH</th>
                    <th colspan="3" style="min-width: 100px">DO</th>
                    <th colspan="3">{{ $t('wq.temperature') }}</th>
                    <th colspan="3" style="min-width: 100px">{{ $t('wq.turbidity') }}</th>
                    <th colspan="3" style="min-width: 100px">{{ $t('wq.wh') }}</th>
                    <th colspan="3" style="min-width: 100px">{{ $t('wq.salinity') }}</th>
                    <th colspan="3">{{ $t('wq.oxygen') }}</th>
                    <th colspan="3" style="min-width: 100px">ORP</th>
                    <th colspan="3" style="width: 200px;">{{ $t('wq.color') }}</th>
                    <th colspan="3" style="width: 200px;">
                      <div class="is-flex is-justify-content-space-between">
                        {{ $t('wq.weather') }}
                        <b-switch v-model="syncWeather" class="mr-0 has-text-weight-normal">Sync Input</b-switch>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th v-for="n in times.general.length" :key="`generalTime${n}`" class="p-0">
                      <b-timepicker
                          :placeholder="`Time ${(n - 1)  % 3 + 1}`"
                          icon="calendar-text"
                          locale="id-ID"
                          v-model="times.general[(n - 1)]"
                          style="min-width: 120px"
                          editable/>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, key) in selectedGeneral" :key="key">
                    <td class="has-text-centered is-sticky">
                      {{ item.pond.name }}
                    </td>
                    <td class="p-0" v-for="n in 3" :key="`ph-${n}`">
                      <b-input ref="input-general" class="input-data"
                               @focus.prevent="handleFocus((key * 3 * 10) + (n - 1), 'general')"
                               @keydown.prevent.native.right="handleArrowRight('general')"
                               @keydown.prevent.native.left="handleArrowLeft('general')"
                               @keydown.prevent.native.down="handleArrowDown('general')"
                               @keydown.prevent.native.up="handleArrowUp('general')"
                               placeholder="0.0" type="number" step="any" v-model.number="item.ph[n - 1]"/>
                    </td>
                    <td class="p-0" v-for="n in 3" :key="`do-${n}`">
                      <b-input ref="input-general" class="input-data"
                               @focus="handleFocus((key * 3 * 10) + (n - 1) + 3, 'general')"
                               @keydown.prevent.native.right="handleArrowRight('general')"
                               @keydown.prevent.native.left="handleArrowLeft('general')"
                               @keydown.prevent.native.down="handleArrowDown('general')"
                               @keydown.prevent.native.up="handleArrowUp('general')"
                               placeholder="0.0 Mg/L" type="number" step="any" v-model.number="item.do[n - 1]"/>
                    </td>
                    <td class="p-0" v-for="n in 3" :key="`temperature-${n}`">
                      <b-input ref="input-general" class="input-data"
                               @focus="handleFocus((key * 3 * 10) + (n - 1) + 6, 'general')"
                               @keydown.prevent.native.right="handleArrowRight('general')"
                               @keydown.prevent.native.left="handleArrowLeft('general')"
                               @keydown.prevent.native.down="handleArrowDown('general')"
                               @keydown.prevent.native.up="handleArrowUp('general')"
                               placeholder="0.0 C" type="number" step="any" v-model.number="item.temperature[n - 1]"/>
                    </td>
                    <td class="p-0" v-for="n in 3" :key="`turbidity-${n}`">
                      <b-input ref="input-general" class="input-data"
                               @focus="handleFocus((key * 3 * 10) + (n - 1) + 9, 'general')"
                               @keydown.prevent.native.right="handleArrowRight('general')"
                               @keydown.prevent.native.left="handleArrowLeft('general')"
                               @keydown.prevent.native.down="handleArrowDown('general')"
                               @keydown.prevent.native.up="handleArrowUp('general')"
                               placeholder="0.0 cm" type="number" step="any" v-model.number="item.turbidity[n - 1]"/>
                    </td>
                    <td class="p-0" v-for="n in 3" :key="`water_height-${n}`">
                      <b-input ref="input-general" class="input-data"
                               @focus="handleFocus((key * 3 * 10) + (n - 1) + 12, 'general')"
                               @keydown.prevent.native.right="handleArrowRight('general')"
                               @keydown.prevent.native.left="handleArrowLeft('general')"
                               @keydown.prevent.native.down="handleArrowDown('general')"
                               @keydown.prevent.native.up="handleArrowUp('general')"
                               placeholder="0.0 cm" type="number" step="any" v-model.number="item.water_height[n - 1]"/>
                    </td>
                    <td class="p-0" v-for="n in 3" :key="`salinity-${n}`">
                      <b-input ref="input-general" class="input-data"
                               @focus="handleFocus((key * 3 * 10) + (n - 1) + 15, 'general')"
                               @keydown.prevent.native.right="handleArrowRight('general')"
                               @keydown.prevent.native.left="handleArrowLeft('general')"
                               @keydown.prevent.native.down="handleArrowDown('general')"
                               @keydown.prevent.native.up="handleArrowUp('general')"
                               placeholder="0.0 ppt" type="number" step="any" v-model.number="item.salinity[n - 1]"/>
                    </td>
                    <td class="p-0" v-for="n in 3" :key="`oxygen-${n}`">
                      <b-input ref="input-general" class="input-data"
                               @focus="handleFocus((key * 3 * 10) + (n - 1) + 18, 'general')"
                               @keydown.prevent.native.right="handleArrowRight('general')"
                               @keydown.prevent.native.left="handleArrowLeft('general')"
                               @keydown.prevent.native.down="handleArrowDown('general')"
                               @keydown.prevent.native.up="handleArrowUp('general')"
                               placeholder="0.0%" type="number" step="any" v-model.number="item.oxygen[n - 1]"/>
                    </td>
                    <td class="p-0" v-for="n in 3" :key="`orp-${n}`">
                      <b-input ref="input-general" class="input-data"
                               @focus="handleFocus((key * 3 * 10) + (n - 1) + 21, 'general')"
                               @keydown.prevent.native.right="handleArrowRight('general')"
                               @keydown.prevent.native.left="handleArrowLeft('general')"
                               @keydown.prevent.native.down="handleArrowDown('general')"
                               @keydown.prevent.native.up="handleArrowUp('general')"
                               placeholder="0.0 mV" type="number" step="any" v-model.number="item.orp[n - 1]"/>
                    </td>
                    <td class="p-0" v-for="n in 3" :key="`color-${n}`">
                      <b-select ref="input-general" class="input-data"
                                @focus.prevent="handleFocus((key * 3 * 10) + (n - 1) + 24, 'general')"
                                @keydown.prevent.native.right="handleArrowRight('general')"
                                @keydown.prevent.native.left="handleArrowLeft('general')"
                                @keydown.prevent.native.down="handleArrowDown('general')"
                                @keydown.prevent.native.up="handleArrowUp('general')" type="is-secondary is-light"
                                :placeholder="$t('select') + ' ' +  $t('wq.color')" expanded
                                style="min-width: 140px;"
                                v-model="item.color[n - 1]">
                        <option :value="item" v-for="item in $getColor" :key="`color-${item.value}`">{{ item[$labelLang] }}</option>
                      </b-select>
                    </td>
                    <td class="p-0" v-for="n in 3" :key="`weather-${n}`">
                      <b-select ref="input-general" class="input-data"
                                @focus.prevent="handleFocus((key * 3 * 10) + (n - 1) + 27, 'general')"
                                @keydown.prevent.native.right="handleArrowRight('general')"
                                @keydown.prevent.native.left="handleArrowLeft('general')"
                                @keydown.prevent.native.down="handleArrowDown('general')"
                                @keydown.prevent.native.up="handleArrowUp('general')" type="is-secondary is-light"
                                :placeholder="$t('select') + ' ' +  $t('wq.weather')" expanded
                                @input="value => handleInputWeather(value, n -1)"
                                style="min-width: 140px;"
                                v-model="item.weather[n - 1]">
                        <option :value="item" v-for="item in $getWeather" :key="`weather-${item.value}`">{{ item[$labelLang] }}</option>
                      </b-select>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="b-table custom-table" v-if="selectedAction === 'chemical'">
              <div class="table-wrapper has-sticky-header">
                <table class="table is-bordered" id="table-chemical">
                  <thead>
                  <tr class="header-row">
                    <th>{{ $t('farm.pond') }}</th>
                    <th style="min-width: 90px">NO2</th>
                    <th style="min-width: 90px">NO3</th>
                    <th style="min-width: 90px">PO4</th>
                    <th style="min-width: 90px">TOM</th>
                    <th style="min-width: 90px">NH3</th>
                    <th style="min-width: 90px">NH4</th>
                    <th style="min-width: 90px">TAN</th>
                    <th style="min-width: 90px">CO3</th>
                    <th style="min-width: 90px">HCO3</th>
                    <th style="min-width: 90px">Alkalinity</th>
                    <th style="min-width: 90px">Ca</th>
                    <th style="min-width: 90px">Mg</th>
                    <th style="min-width: 90px">Hardness</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, key) in selectedChemical" :key="key">
                    <td class="has-text-centered is-sticky">
                      {{ item.pond.name }}
                    </td>
                    <td class="p-0">
                      <b-input ref="input-chemical" class="input-data"
                               @focus="handleFocus((key * 13), 'chemical')"
                               @keydown.prevent.native.right="handleArrowRight('chemical')"
                               @keydown.prevent.native.left="handleArrowLeft('chemical')"
                               @keydown.prevent.native.down="handleArrowDown('chemical')"
                               @keydown.prevent.native.up="handleArrowUp('chemical')"
                               placeholder="0.0 ppt" type="number" step="any" v-model.number="item.no2"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-chemical" class="input-data"
                               @focus="handleFocus((key * 13) + 1, 'chemical')"
                               @keydown.prevent.native.right="handleArrowRight('chemical')"
                               @keydown.prevent.native.left="handleArrowLeft('chemical')"
                               @keydown.prevent.native.down="handleArrowDown('chemical')"
                               @keydown.prevent.native.up="handleArrowUp('chemical')"
                               placeholder="0.0 ppt" type="number" step="any" v-model.number="item.no3"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-chemical" class="input-data"
                               @focus="handleFocus((key * 13) + 2, 'chemical')"
                               @keydown.prevent.native.right="handleArrowRight('chemical')"
                               @keydown.prevent.native.left="handleArrowLeft('chemical')"
                               @keydown.prevent.native.down="handleArrowDown('chemical')"
                               @keydown.prevent.native.up="handleArrowUp('chemical')"
                               placeholder="0.0 ppt" type="number" step="any" v-model.number="item.po4"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-chemical" class="input-data"
                               @focus="handleFocus((key * 13) + 3, 'chemical')"
                               @keydown.prevent.native.right="handleArrowRight('chemical')"
                               @keydown.prevent.native.left="handleArrowLeft('chemical')"
                               @keydown.prevent.native.down="handleArrowDown('chemical')"
                               @keydown.prevent.native.up="handleArrowUp('chemical')"
                               placeholder="0.0 ppt" type="number" step="any" v-model.number="item.tom"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-chemical" class="input-data"
                               @focus="handleFocus((key * 13) + 4, 'chemical')"
                               @keydown.prevent.native.right="handleArrowRight('chemical')"
                               @keydown.prevent.native.left="handleArrowLeft('chemical')"
                               @keydown.prevent.native.down="handleArrowDown('chemical')"
                               @keydown.prevent.native.up="handleArrowUp('chemical')"
                               placeholder="0.0 ppt" type="number" step="any" v-model.number="item.nh3"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-chemical" class="input-data"
                               @focus="handleFocus((key * 13) + 5, 'chemical')"
                               @keydown.prevent.native.right="handleArrowRight('chemical')"
                               @keydown.prevent.native.left="handleArrowLeft('chemical')"
                               @keydown.prevent.native.down="handleArrowDown('chemical')"
                               @keydown.prevent.native.up="handleArrowUp('chemical')"
                               placeholder="0.0 ppt" type="number" step="any" v-model.number="item.nh4"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-chemical" class="input-data"
                               @focus="handleFocus((key * 13) + 6, 'chemical')"
                               @keydown.prevent.native.right="handleArrowRight('chemical')"
                               @keydown.prevent.native.left="handleArrowLeft('chemical')"
                               @keydown.prevent.native.down="handleArrowDown('chemical')"
                               @keydown.prevent.native.up="handleArrowUp('chemical')"
                               placeholder="0.0 ppt" type="number" step="any" v-model.number="item.tan"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-chemical" class="input-data"
                               @focus="handleFocus((key * 13) + 7, 'chemical')"
                               @keydown.prevent.native.right="handleArrowRight('chemical')"
                               @keydown.prevent.native.left="handleArrowLeft('chemical')"
                               @keydown.prevent.native.down="handleArrowDown('chemical')"
                               @keydown.prevent.native.up="handleArrowUp('chemical')"
                               placeholder="0.0 ppt" type="number" step="any" v-model.number="item.co3"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-chemical" class="input-data"
                               @focus="handleFocus((key * 13) + 8, 'chemical')"
                               @keydown.prevent.native.right="handleArrowRight('chemical')"
                               @keydown.prevent.native.left="handleArrowLeft('chemical')"
                               @keydown.prevent.native.down="handleArrowDown('chemical')"
                               @keydown.prevent.native.up="handleArrowUp('chemical')"
                               placeholder="0.0 ppt" type="number" step="any" v-model.number="item.hco3"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-chemical" class="input-data"
                               @focus="handleFocus((key * 13) + 9, 'chemical')"
                               @keydown.prevent.native.right="handleArrowRight('chemical')"
                               @keydown.prevent.native.left="handleArrowLeft('chemical')"
                               @keydown.prevent.native.down="handleArrowDown('chemical')"
                               @keydown.prevent.native.up="handleArrowUp('chemical')"
                               placeholder="0.0 ppt" type="number" step="any" v-model.number="item.alkalinity"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-chemical" class="input-data"
                               @focus="handleFocus((key * 13) + 10, 'chemical')"
                               @keydown.prevent.native.right="handleArrowRight('chemical')"
                               @keydown.prevent.native.left="handleArrowLeft('chemical')"
                               @keydown.prevent.native.down="handleArrowDown('chemical')"
                               @keydown.prevent.native.up="handleArrowUp('chemical')"
                               placeholder="0.0 ppt" type="number" step="any" v-model.number="item.ca"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-chemical" class="input-data"
                               @focus="handleFocus((key * 13) + 11, 'chemical')"
                               @keydown.prevent.native.right="handleArrowRight('chemical')"
                               @keydown.prevent.native.left="handleArrowLeft('chemical')"
                               @keydown.prevent.native.down="handleArrowDown('chemical')"
                               @keydown.prevent.native.up="handleArrowUp('chemical')"
                               placeholder="0.0 ppt" type="number" step="any" v-model.number="item.mg"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-chemical" class="input-data"
                               @focus="handleFocus((key * 13) + 12, 'chemical')"
                               @keydown.prevent.native.right="handleArrowRight('chemical')"
                               @keydown.prevent.native.left="handleArrowLeft('chemical')"
                               @keydown.prevent.native.down="handleArrowDown('chemical')"
                               @keydown.prevent.native.up="handleArrowUp('chemical')"
                               placeholder="0.0 ppt" type="number" step="any" v-model.number="item.hard"/>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="b-table custom-table" v-if="selectedAction === 'biological'">
              <div class="table-wrapper has-sticky-header">
                <table class="table is-bordered" id="table-biological">
                  <thead>
                  <tr class="header-row">
                    <th>{{ $t('farm.pond') }}</th>
                    <th style="min-width: 120px">TBC</th>
                    <th style="min-width: 120px">TVC</th>
                    <th style="min-width: 120px">TVC/TBC(%)</th>
                    <th style="min-width: 120px">YVC</th>
                    <th style="min-width: 120px">GVC</th>
                    <th style="min-width: 120px">LBC</th>
                    <th style="min-width: 120px">BVC</th>
                    <th style="min-width: 120px">%BGA</th>
                    <th style="min-width: 120px">%GA</th>
                    <th style="min-width: 120px">%GGA</th>
                    <th style="min-width: 120px">%DINO</th>
                    <th style="min-width: 120px">%DIA</th>
                    <th style="min-width: 120px">%PRO</th>
                    <th style="min-width: 120px">%EUG</th>
                    <th style="min-width: 120px">Plankton</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, key) in selectedBiological" :key="key">
                    <td class="has-text-centered is-sticky">
                      {{ item.pond.name }}
                    </td>
                    <td class="p-0">
                      <b-input ref="input-biological" class="input-data"
                               @focus="handleFocus((key * 13), 'biological')"
                               @keydown.prevent.native.right="handleArrowRight('biological')"
                               @keydown.prevent.native.left="handleArrowLeft('biological')"
                               @keydown.prevent.native.down="handleArrowDown('biological')"
                               @keydown.prevent.native.up="handleArrowUp('biological')"
                               placeholder="0.0" type="number" step="any" v-model.number="item.tbc"/>
                    </td>
                    <td class="p-0">
                      <b-input placeholder="-" type="number" step="any" v-model.number="item.tvc" disabled/>
                    </td>
                    <td class="p-0">
                      <b-input placeholder="-" type="number" step="any" v-model.number="item.tvctbc" disabled/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-biological" class="input-data"
                               @focus="handleFocus((key * 13) + 1, 'biological')"
                               @keydown.prevent.native.right="handleArrowRight('biological')"
                               @keydown.prevent.native.left="handleArrowLeft('biological')"
                               @keydown.prevent.native.down="handleArrowDown('biological')"
                               @keydown.prevent.native.up="handleArrowUp('biological')" placeholder="0.0" type="number"
                               step="any" v-model.number="item.yvc"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-biological" class="input-data"
                               @focus="handleFocus((key * 13) + 2, 'biological')"
                               @keydown.prevent.native.right="handleArrowRight('biological')"
                               @keydown.prevent.native.left="handleArrowLeft('biological')"
                               @keydown.prevent.native.down="handleArrowDown('biological')"
                               @keydown.prevent.native.up="handleArrowUp('biological')" placeholder="0.0" type="number"
                               step="any" v-model.number="item.gvc"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-biological" class="input-data"
                               @focus="handleFocus((key * 13) + 3, 'biological')"
                               @keydown.prevent.native.right="handleArrowRight('biological')"
                               @keydown.prevent.native.left="handleArrowLeft('biological')"
                               @keydown.prevent.native.down="handleArrowDown('biological')"
                               @keydown.prevent.native.up="handleArrowUp('biological')" placeholder="0.0" type="number"
                               step="any" v-model.number="item.lbc"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-biological" class="input-data"
                               @focus="handleFocus((key * 13) + 4, 'biological')"
                               @keydown.prevent.native.right="handleArrowRight('biological')"
                               @keydown.prevent.native.left="handleArrowLeft('biological')"
                               @keydown.prevent.native.down="handleArrowDown('biological')"
                               @keydown.prevent.native.up="handleArrowUp('biological')" placeholder="0.0" type="number"
                               step="any" v-model.number="item.bvc"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-biological" class="input-data"
                               @focus="handleFocus((key * 13) + 5, 'biological')"
                               @keydown.prevent.native.right="handleArrowRight('biological')"
                               @keydown.prevent.native.left="handleArrowLeft('biological')"
                               @keydown.prevent.native.down="handleArrowDown('biological')"
                               @keydown.prevent.native.up="handleArrowUp('biological')" placeholder="0.0%" type="number"
                               step="any" v-model.number="item.bga"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-biological" class="input-data"
                               @focus="handleFocus((key * 13) + 6, 'biological')"
                               @keydown.prevent.native.right="handleArrowRight('biological')"
                               @keydown.prevent.native.left="handleArrowLeft('biological')"
                               @keydown.prevent.native.down="handleArrowDown('biological')"
                               @keydown.prevent.native.up="handleArrowUp('biological')" placeholder="0.0%" type="number"
                               step="any" v-model.number="item.ga"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-biological" class="input-data"
                               @focus="handleFocus((key * 13) + 7, 'biological')"
                               @keydown.prevent.native.right="handleArrowRight('biological')"
                               @keydown.prevent.native.left="handleArrowLeft('biological')"
                               @keydown.prevent.native.down="handleArrowDown('biological')"
                               @keydown.prevent.native.up="handleArrowUp('biological')" placeholder="0.0%" type="number"
                               step="any" v-model.number="item.gga"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-biological" class="input-data"
                               @focus="handleFocus((key * 13) + 8, 'biological')"
                               @keydown.prevent.native.right="handleArrowRight('biological')"
                               @keydown.prevent.native.left="handleArrowLeft('biological')"
                               @keydown.prevent.native.down="handleArrowDown('biological')"
                               @keydown.prevent.native.up="handleArrowUp('biological')" placeholder="0.0%" type="number"
                               step="any" v-model.number="item.dino"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-biological" class="input-data"
                               @focus="handleFocus((key * 13) + 9, 'biological')"
                               @keydown.prevent.native.right="handleArrowRight('biological')"
                               @keydown.prevent.native.left="handleArrowLeft('biological')"
                               @keydown.prevent.native.down="handleArrowDown('biological')"
                               @keydown.prevent.native.up="handleArrowUp('biological')" placeholder="0.0%" type="number"
                               step="any" v-model.number="item.dia"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-biological" class="input-data"
                               @focus="handleFocus((key * 13) + 10, 'biological')"
                               @keydown.prevent.native.right="handleArrowRight('biological')"
                               @keydown.prevent.native.left="handleArrowLeft('biological')"
                               @keydown.prevent.native.down="handleArrowDown('biological')"
                               @keydown.prevent.native.up="handleArrowUp('biological')" placeholder="0.0%" type="number"
                               step="any" v-model.number="item.pro"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-biological" class="input-data"
                               @focus="handleFocus((key * 13) + 11, 'biological')"
                               @keydown.prevent.native.right="handleArrowRight('biological')"
                               @keydown.prevent.native.left="handleArrowLeft('biological')"
                               @keydown.prevent.native.down="handleArrowDown('biological')"
                               @keydown.prevent.native.up="handleArrowUp('biological')" placeholder="0.0%" type="number"
                               step="any" v-model.number="item.eug"/>
                    </td>
                    <td class="p-0">
                      <b-input ref="input-biological" class="input-data"
                               @focus="handleFocus((key * 13) + 12, 'biological')"
                               @keydown.prevent.native.right="handleArrowRight('biological')"
                               @keydown.prevent.native.left="handleArrowLeft('biological')"
                               @keydown.prevent.native.down="handleArrowDown('biological')"
                               @keydown.prevent.native.up="handleArrowUp('biological')" placeholder="0.0" type="number"
                               step="any" v-model.number="item.plankton"/>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <p class="has-text-danger" v-else>No Ponds have been started the cycle yet <span
              v-if="selectedBlock && selectedBlock.block_id">on Block {{ selectedBlock.block_name }}</span></p>
        </div>
      </section>

      <footer class="is-flex is-justify-content-flex-end mt-3">
        <b-button type="is-primary is-gradient" class="ml-2" @click="handleSubmit">{{ $t('save') }}</b-button>
      </footer>

    </div>
  </div>
</template>

<script>
import SgTab from "@/components/Sg/SgTab";
import SgSelect from "@/components/Sg/SgSelect";

export default {
  name: "InputWaterQualityBulk",
  components: {SgSelect, SgTab},
  computed: {
    action() {
      return this.selectedAction.toLowerCase()
    },

    farm_id() {
      return parseInt(this.$route.params.farm_id)
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    filteredPonds() {
      let ponds = this.selectedFarm.ponds

      if (this.selectedBlock && this.selectedBlock.block_id) ponds = ponds.filter(e => e.block_id === this.selectedBlock.block_id)

      return ponds
    },
    filteredPondsInput() {
      return this.selectedFarm.ponds.filter(e => e.status >= 1)
    },

    selectedGeneral() {
      return this.getDataByType('general')
    },
    selectedChemical() {
      return this.getDataByType('chemical')
    },
    selectedBiological() {
      return this.getDataByType('biological')
    },

    embed() {
      return this.$route.query.output === 'embed'
    },
  },
  data() {
    return {
      selectedAction: 'general',
      selectedBlock: null,

      filteredCycles: [],

      selectedDate: {
        general: null,
        chemical: null,
        biological: null,
      },

      data: {
        general: [],
        chemical: [],
        biological: [],
      },

      times: {
        general: [],
        chemical: null,
        biological: null,
      },

      loaded: false,

      selectedInput: 0,
      maxInput: 0,
      maxInputForm: {
        general: 30,
        chemical: 13,
        biological: 13
      },

      syncWeather: true,
    }
  },
  watch: {
    'selectedFarm.blocks'(value) {
      this.selectedBlock = value[0]
    },
    'selectedFarm.ponds'() {
      this.init()
    },
    data: {
      deep: true,
      handler(value) {
        localStorage.setItem('sgara.pgsql.input.wq.data', JSON.stringify(value))
      },
    },
    selectedDate: {
      deep: true,
      handler(value) {
        localStorage.setItem('sgara.pgsql.input.wq.date', JSON.stringify(value))
      },
    },
    times: {
      deep: true,
      handler(value) {
        localStorage.setItem('sgara.pgsql.input.wq.times', JSON.stringify(value))
      },
    },
  },
  created() {
    this.selectedAction = this.$route.query.type || 'general'

    this.init()
  },
  methods: {
    handleFocus(n, type) {
      this.selectedInput = n
      this.maxInput = this.$refs[`input-${type}`].length - 1
    },

    handleArrowRight(type) {
      if (this.selectedInput >= this.maxInput) this.selectedInput = -1
      let find = document.querySelectorAll(`#table-${type} .input-data`)[this.selectedInput + 1]
      this.selectElement(find)
    },

    handleArrowLeft(type) {
      if (this.selectedInput <= 0) this.selectedInput = document.querySelectorAll(`#table-${type} .input-data:not(:disabled)`).length
      let find = document.querySelectorAll(`#table-${type} .input-data:not(:disabled)`)[this.selectedInput - 1]
      this.selectElement(find)
    },

    handleArrowDown(type) {
      if (this.selectedInput + this.maxInputForm[type] > this.maxInput) this.selectedInput = -(this.maxInput + 1 - this.selectedInput) + this.maxInputForm[type]
      else this.selectedInput += this.maxInputForm[type]
      let find = document.querySelectorAll(`#table-${type} .input-data:not(:disabled)`)[this.selectedInput]
      this.selectElement(find)
    },

    handleArrowUp(type) {
      if (this.selectedInput - this.maxInputForm[type] < 0) this.selectedInput = this.maxInput + this.selectedInput + 1
      this.selectedInput -= this.maxInputForm[type]
      let find = document.querySelectorAll(`#table-${type} .input-data:not(:disabled)`)[this.selectedInput]
      this.selectElement(find)
    },

    selectElement(el) {
      if (el) {
        if (el.querySelector('input')) el.querySelector('input').focus()
        else if (el.querySelector('select')) el.querySelector('select').focus()
        else if (el.querySelector('button')) el.querySelector('button').focus()
      }
    },

    init() {
      if (this.selectedFarm.ponds.length) {
        if (this.selectedFarm.blocks.length) this.selectedBlock = this.selectedFarm.blocks[0]

        let data = JSON.parse(localStorage.getItem('sgara.pgsql.input.wq.data'))
        let date = JSON.parse(localStorage.getItem('sgara.pgsql.input.wq.date'))
        let times = JSON.parse(localStorage.getItem('sgara.pgsql.input.wq.times'))

        let isFilled = []

        if (data && date && times) {
          for (let k in data) {
            let item = data[k]
            if (this.isFilledData(item, k)) isFilled.push(k)
          }
        }

        let type = ['general', 'chemical', 'biological']
            .filter(e => !isFilled.includes(e))

        this.resetForm(type, data, date, times)
      }
    },

    isFilledData(data, type) {
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].data.length; j++) {
          for (const key in data[i].data[j]) {
            if (key !== 'pond') {
              if (type === 'general') {
                if (data[i].data[j][key].length) {
                  return true
                }
              } else {
                if (data[i].data[j][key]) {
                  return true
                }
              }
            }
          }
        }
      }

      return false
    },

    getDataByType(type) {
      let date = this.$startDay(new Date(this.selectedDate[type])).valueOf()
      let find = this.findItemByDate(type, date)
      find = find ? find.data.filter(e => e.pond.status >= 1) : []
      if (this.selectedBlock && this.selectedBlock.block_id)
        return find.filter(e => e.pond.block_id === this.selectedBlock.block_id)
      return find
    },

    resetForm(type = ['general', 'chemical', 'biological'], data = null, date = null, time = null) {
      this.loaded = false

      let times = ['06:00', '14:00', '22:00']

      this.times.general = []
      if (type.includes('general')) {
        for (let i = 0; i < this.maxInputForm.general; i++) {
          this.times.general.push(new Date(this.$dateFormat(null, 'YYYY-MM-DD') + ' ' + times[i % times.length]))
        }
      } else {
        for (let i = 0; i < time.general.length; i++) {
          this.times.general.push(new Date(time.general[i]))
        }
      }

      if (type.includes('chemical')) this.times.chemical = new Date(this.$dateFormat(null, 'YYYY-MM-DD') + ' ' + times[0])
      else if (time && time.chemical) this.times.chemical = new Date(time.chemical)
      if (type.includes('biological')) this.times.biological = new Date(this.$dateFormat(null, 'YYYY-MM-DD') + ' ' + times[0])
      else if (time && time.biological) this.times.biological = new Date(time.biological)

      for (let item of ['general', 'chemical', 'biological']) {
        if (type.includes(item)) {
          this.selectedDate[item] = new Date()
          this.data[item] = []
          this.addData(item)
        } else {
          if (data && date && data[item] && date[item]) {
            this.data[item] = data[item]
            this.selectedDate[item] = new Date(date[item])
          }
        }
      }

      this.$nextTick(() => {
        this.loaded = true
      })
    },

    findItemByDate(type, date) {
      return this.data[type].find(e => e.date === date)
    },

    addData(type) {
      let date = this.$startDay(new Date(this.selectedDate[type])).valueOf()

      let find = this.findItemByDate(type, date)

      if (!find) {
        let item = {
          date: date,
          data: []
        }

        for (let i = 0; i < this.filteredPondsInput.length; i++) {
          if (type === 'general') {
            item.data.push({
              pond: this.filteredPondsInput[i],
              ph: [],
              do: [],
              temperature: [],
              turbidity: [],
              water_height: [],
              salinity: [],
              oxygen: [],
              orp: [],
              color: [],
              weather: [],
            })
          } else {
            item.data.push({
              pond: this.filteredPondsInput[i],
            })
          }
        }

        this.data[type].push(item)

        this.data[type] = this.data[type].sort((a, b) => a.date > b.date ? 1 : -1)
      }
    },

    removeData(key, type) {
      let isSameDay = this.$isSameDay(this.data[type][key].date, this.selectedDate[type])

      this.data[type].splice(key, 1)
      if (this.data[type].length === 0) this.addData(type)

      if (isSameDay) this.selectedDate[type] = new Date(this.data[type][key === 0 ? 0 : key - 1].date)
    },

    handleInputWeather(value, n) {
      if (this.syncWeather) {
        for (let i = 0; i < this.selectedGeneral.length; i++) {
          this.selectedGeneral[i].weather[n] = value
        }
      }
    },

    async handleSubmit() {
      this.$loading()

      let data = []
      let ponds = []

      for (let k in this.data) {
        data = this.data[k].reduce((a, b) => {
          if (b.date && b.data) {
            for (let i = 0; i < b.data.length; i++) {
              if (k === 'general') {

                let isDataFilled = false

                for (let l in b.data[i]) {
                  if (l !== 'pond' && b.data[i][l].length) {
                    isDataFilled = true
                  }
                }

                if (isDataFilled) {
                  for (let j = 0; j < 3; j++) {
                    let isDataByTimeFilled = false
                    let item = {
                      pond: b.data[i].pond,
                      type: k,
                      date: this.$timestamp(this.$dateFormat(b.date, 'YYYY-MM-DD') + ' ' + this.$timeFormat(this.times.general[j]))
                    }
                    for (let l in b.data[i]) {
                      if (l !== 'pond' && b.data[i][l][j]) {
                        isDataByTimeFilled = true
                        item[l] = b.data[i][l][j]
                      }
                    }
                    if (isDataByTimeFilled) a.push(item)
                  }
                  if (!ponds.find(f => f.pond_id === b.data[i].pond.pond_id)) ponds.push(b.data[i].pond)
                }
              } else {
                let isDataFilled = false

                for (let l in b.data[i]) {
                  if (l !== 'pond' && typeof b.data[i][l] === 'number') {
                    isDataFilled = true
                  }
                }

                if (isDataFilled) {
                  let item = {
                    pond: b.data[i].pond,
                    type: k,
                    date: this.$timestamp(this.$dateFormat(b.date, 'YYYY-MM-DD') + ' ' + this.$timeFormat(this.times[k])),
                  }
                  for (let l in b.data[i]) {
                    if (l !== 'pond' && typeof b.data[i][l] === 'number') {
                      item[l] = b.data[i][l]
                    }
                  }
                  a.push(item)
                  if (!ponds.find(f => f.pond_id === b.data[i].pond.pond_id)) ponds.push(b.data[i].pond)
                }
              }
            }
          }
          return a
        }, data)
      }

      for (let i = 0; i < ponds.length; i++) {
        let cycle = await this.$store.dispatch('farm/getActiveCycle', ponds[i].pond_id)
        if (cycle) ponds[i].cycle = cycle
      }

      let general = []
      let chemical = []
      let biological = []

      let timediff = new Date().getTimezoneOffset() * -1

      for (let i = 0; i < data.length; i++) {
        let item = data[i]
        let pond = ponds.find(e => e.pond_id === item.pond.pond_id)

        if (pond && pond.cycle) {
          let cycle = pond.cycle

          if (item.type === 'general') {
            general.push({
              farm_id: this.selectedFarm.farm_id,
              block_id: pond.block_id,
              pond_id: pond.pond_id,
              cycle_id: cycle.cycle_id,
              color: item.color ? item.color.value : -99,
              weather: item.weather ? item.weather.value : -99,
              ph: typeof item.ph === 'number' ? item.ph : -99,
              do: typeof item.do === 'number' ? item.do : -99,
              salinity: typeof item.salinity === 'number' ? item.salinity : -99,
              temperature: typeof item.temperature === 'number' ? item.temperature : -99,
              turbidity: typeof item.turbidity === 'number' ? item.turbidity : -99,
              water_height: typeof item.water_height === 'number' ? item.water_height : -99,
              oxygen: typeof item.oxygen === 'number' ? item.oxygen : -99,
              orp: typeof item.orp === 'number' ? item.orp : -99,
              created_at: item.date,
              date: this.$startDay(item.date).valueOf(),
              timediff,
            })
          } else if (item.type === 'chemical') {
            chemical.push({
              farm_id: this.selectedFarm.farm_id,
              block_id: pond.block_id,
              pond_id: pond.pond_id,
              cycle_id: cycle.cycle_id,
              alkalinity: typeof item.alkalinity === 'number' ? item.alkalinity : -99,
              nh4: typeof item.nh4 === 'number' ? item.nh4 : -99,
              no2: typeof item.no2 === 'number' ? item.no2 : -99,
              no3: typeof item.no3 === 'number' ? item.no3 : -99,
              po4: typeof item.po4 === 'number' ? item.po4 : -99,
              tom: typeof item.tom === 'number' ? item.tom : -99,
              tan: typeof item.tan === 'number' ? item.tan : -99,
              nh3: typeof item.nh3 === 'number' ? item.nh3 : -99,
              co3: typeof item.co3 === 'number' ? item.co3 : -99,
              hco3: typeof item.hco3 === 'number' ? item.hco3 : -99,
              ca: typeof item.ca === 'number' ? item.ca : -99,
              mg: typeof item.mg === 'number' ? item.mg : -99,
              hard: typeof item.hard === 'number' ? item.hard : -99,
              created_at: item.date,
              date: this.$startDay(item.date).valueOf(),
              timediff,
            })
          } else if (item.type === 'biological') {
            biological.push({
              farm_id: this.selectedFarm.farm_id,
              block_id: pond.block_id,
              pond_id: pond.pond_id,
              cycle_id: cycle.cycle_id,
              tbc: typeof item.tbc === 'number' ? item.tbc : -99,
              yvc: typeof item.yvc === 'number' ? item.yvc : -99,
              gvc: typeof item.gvc === 'number' ? item.gvc : -99,
              lbc: typeof item.lbc === 'number' ? item.lbc : -99,
              bvc: typeof item.bvc === 'number' ? item.bvc : -99,
              bga: typeof item.bga === 'number' ? item.bga : -99,
              ga: typeof item.ga === 'number' ? item.ga : -99,
              gga: typeof item.gga === 'number' ? item.gga : -99,
              dino: typeof item.dino === 'number' ? item.dino : -99,
              dia: typeof item.dia === 'number' ? item.dia : -99,
              pro: typeof item.pro === 'number' ? item.pro : -99,
              eug: typeof item.eug === 'number' ? item.eug : -99,
              plankton: typeof item.plankton === 'number' ? item.plankton : -99,
              created_at: item.date,
              date: this.$startDay(item.date).valueOf(),
              timediff,
            })
          }
        }
      }

      if (general.length) await this.$store.dispatch('farm/addWaterQualityGeneral', general)
      if (chemical.length) await this.$store.dispatch('farm/addWaterQualityChemical', chemical)
      if (biological.length) await this.$store.dispatch('farm/addWaterQualityBiological', biological)

      localStorage.removeItem('sgara.pgsql.input.wq.data')
      localStorage.removeItem('sgara.pgsql.input.wq.date')
      localStorage.removeItem('sgara.pgsql.input.wq.times')
      this.$emit('close')
      this.$emit('submit')
      this.$loading(false)
      this.resetForm()

      if (!this.embed) {
        this.$router.push({
          path: '/farm/' + this.selectedFarm.farm_id,
          query: {
            type: this.selectedAction,
          },
        })
      }
    },
  }
}
</script>

<style lang="scss">
</style>
