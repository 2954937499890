<template>
  <div class="input-data">
    <h2 class="is-size-5 has-text-weight-bold has-text-dark">{{ $t('wq.wq') }}</h2>

    <input-water-quality-bulk/>
  </div>
</template>

<script>

import InputWaterQualityBulk from "@/components/Farm/WaterQuality/InputWaterQualityBulk";

export default {
  name: "InputWaterQualityForm",
  components: {
    InputWaterQualityBulk
  },
  computed: {
    farm_id() {
      return parseInt(this.$route.params.farm_id)
    },
    embed() {
      return this.$route.query.output === 'embed'
    },
  },
}
</script>
